<template>
  <ion-item :lines="lines" class="ion-no-padding">
    <ion-avatar slot="start" v-if="false">
      <img :src="user.profile_photo_url
          ? user.profile_photo_url
          : 'https://borym.s3.amazonaws.com/media/soft_green_light.png'
        "/>
    </ion-avatar>
    <ion-label>
      <div class="h6">{{ (user.alias) ? `${user.alias} (${user.fullname})` : user.fullname }}</div>
      <span class="ion-text-capitalize">{{ reservationRoomNumber }}</span>
    </ion-label>
    <ion-label slot="end" class="ion-text-right ion-no-margin">
      <!-- Arrival Departure Status -->
      <ion-chip :color="arrivalDepartureStatus.color" v-if="arrivalDepartureStatus.title">
        <ion-label class="ion-text-capitalize">{{
          arrivalDepartureStatus.title
        }}</ion-label>
      </ion-chip>

      <!-- Checkin Status -->
      <ion-chip :color="checkinStatus.color">
        <ion-label class="ion-text-capitalize">{{
          checkinStatus.title
        }}</ion-label>
      </ion-chip>
    </ion-label>
  </ion-item>
</template>

<script>
import { IonItem, IonLabel, IonAvatar, IonChip } from "@ionic/vue";
import { format } from "date-fns";

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    reservation: {
      type: Object,
      required: true
    },
    lines: {
      type: String,
      default: "full",
    },
  },
  components: {
    IonItem,
    IonAvatar,
    IonLabel,
    IonChip,
  },
  computed: {
    checkinStatus() {
      if (this.reservation && this.reservation.checkout_date) return { color: "danger", title: "Checked-Out" };
      else if (this.reservation && this.reservation.checkin_date) return { color: "primary", title: "Checked-In" };

      return { color: "", title: "Not Checked-in" };
    },

    arrivalDepartureStatus() {
      let today = format(new Date(), "yyyy-MM-dd");
      let status = {
        color: "primary",
        title: null,
      };

      if (this.reservation && this.reservation.arrival_date) {
        // Arriving
        if (this.reservation.arrival_date === today)
          status = { color: "warning", title: "Arriving" };

        // Departing
        else if (this.reservation.departure_date === today)
          status = { color: "danger", title: "Departing" };

        // In House
        else if (this.reservation.checkin_date && !this.reservation.checkout_date)
          status = { color: "primary", title: "In-House" };
      }

      return status
    },

    reservationRoomNumber() {
      if (this.reservation && this.reservation.room)
        return `Room ${this.reservation.room.number}`;
      else return `Room not assigned`;
    },
  },
};
</script>

<style scoped>
ion-badge {
  font-weight: 300;
}

ion-item {
  --padding-bottom: 8px;
  --padding-top: 8px;
  --border-color: var(--light-line-color);
}
</style>