<template>
  <base-layout-one page-title="Guests" content-class="background-white">
    <ion-refresher slot="fixed" @ionRefresh="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>

    <section class="ion-padding-top ion-padding-start ion-padding-end">
      <ion-button class="regular-button" expand="block" router-link="/users/create"
        v-if="authUser.role === 'admin' || authUser.role === 'manager'">New Guest</ion-button>
    </section>

    <!-- Search -->
    <section class="padding-left-8 padding-right-8">
      <ion-searchbar placeholder="Search for Guest" @click="showUserSearchModal = true"></ion-searchbar>
    </section>

    <!-- Sections -->
    <section class="ion-padding-start ion-padding-end">
      <ion-segment scrollable value="mothers">
        <ion-segment-button value="mothers" @click="filterUsers('mother')">
          Mothers
        </ion-segment-button>
        <ion-segment-button value="babies" @click="filterUsers('baby')">
          Babies
        </ion-segment-button>
      </ion-segment>
    </section>

    <!-- Guest List -->
    <ion-list class="ion-padding-start ion-padding-end">
      <user-reservation-list-item v-for="user in filteredUsers" v-bind:key="user.id" :user="user"
        :reservation="user.reservation" :router-link="`/users/${user.id}`"></user-reservation-list-item>
    </ion-list>

    <ion-modal :is-open="showUserSearchModal" @didDismiss="() => { showUserSearchModal = false }">
      <user-search-modal @select-user="selectUser"
        @cancel-modal="() => (this.showUserSearchModal = false)"></user-search-modal>
    </ion-modal>
  </base-layout-one>
</template>

<script>
import {
  IonList,
  IonButton,
  IonSegment,
  IonSegmentButton,
  IonSearchbar,
  IonModal,
  IonRefresher,
  IonRefresherContent
} from "@ionic/vue";
import UserReservationListItem from "@/components/users/UserReservationListItem.vue";
import UserSearchModal from "@/components/users/UserSearchModal.vue";
import { format, addDays, subDays } from "date-fns";
import { mapGetters } from "vuex";

export default {
  components: {
    IonRefresher,
    IonRefresherContent,
    IonList,
    IonButton,
    IonSegment,
    IonSegmentButton,
    IonSearchbar,
    IonModal,
    UserReservationListItem,
    UserSearchModal,
  },

  computed: {
    ...mapGetters(["authUser"]),
  },

  data() {
    return {
      users: [],
      filteredUsers: [],
      showUserSearchModal: false,
    };
  },

  ionViewDidEnter() {
    this.fetchUsers();
  },

  methods: {
    async refresh(event) {
      await this.fetchUsers();

      event.target.complete();
    },

    async fetchUsers() {
      // let today = format(new Date(), "yyyy-MM-dd");
      let yesterday = format(subDays(new Date(), 1), "yyyy-MM-dd");
      let tomorrow = format(addDays(new Date(), 1), "yyyy-MM-dd");

      this.axios.get(`${process.env.VUE_APP_API}/reservations?arrival_date_before=${tomorrow}&departure_date_after=${yesterday}`)
        .then((response) => {
          // Get the users from the reservations (these are going to be moms)
          this.users = response.data.reservations.map((reservation) => {
            let user = reservation.user;

            // Set the reservation
            user["reservation"] = reservation;

            return user;
          });

          // Get relations (babies)
          let relations = [];
          for (let user of this.users) {
            for (let relation of user.relations_from) {
              // Set the reservation
              relation.user_to["reservation"] = user.reservation;

              relations.push(relation.user_to);
            }
          }

          // Add this to the list of users
          this.users = this.users.concat(relations);


          // Sort (first on reservation status and then by first name)
          const statusOrder = {
            'In-House': 1,
            'Arriving': 2,
            'Departing': 3,
          };

          // Add the status of the reservation to each user
          this.users = this.users.map((user) => {
            let today = format(new Date(), "yyyy-MM-dd");
            let status = "In-House"

            if (user.reservation && user.reservation.arrival_date) {
              if (user.reservation.arrival_date === today) status = "Arriving"
              else if (user.reservation.departure_date === today) status = "Departing"
              else if (user.reservation.checkin_date && !user.reservation.checkout_date) status = "In-House"
            }

            return {
              ...user,
              status: status
            }
          })

          this.users = this.users.sort((a, b) => {
            const statusA = a.status;
            const statusB = b.status;

            // Compare based on the numeric values assigned to the statuses
            const statusComparison = statusOrder[statusA] - statusOrder[statusB];

            if (statusComparison !== 0) {
              return statusComparison; // If status is different, return the result of the status comparison
            }

            // If status is the same, compare by first_name
            return a.first_name.localeCompare(b.first_name);
          });

          // By default filter for mothers
          this.filterUsers("mother");
        })
        .catch((error) => { console.log(error) });
    },


    /**
     * Called by the User Search Modal to dismiss the modal and load the user
     * Consider deprecating this and replacing how the User Search Modal is implemented
     * 
     * @param {Object} user 
     */
    selectUser(user) {
      this.showUserSearchModal = false;
      this.$router.push(`/users/${user.id}`);
    },

    /**
     * Filters the shown users (mothers or babies)
     * 
     * @param {String} filter 
     */
    filterUsers(filter) {
      if (filter) this.filteredUsers = this.users.filter((x) => x.role === filter);
      else this.filteredUsers = this.users;
    },
  },
};
</script>